<template>
  <div class="xa-upload" :class="{ 'xa-upload--disable': disable }">
    <div class="xa-upload__head xa-cell xa-txt-secondary">
      <span class="xa-flex" v-html="topTip"></span>
    </div>
    <div class="xa-upload__wrap">
      <template v-for="file in uploadList">
        <div
          :key="file.thumb_url"
          :style="imgSize"
          class="xa-upload__item"
          v-loading="!file.STATUS"
        >
          <img
            class="xa-upload__img"
            :src="file.thumb_url || file.SRC"
            @click.self="preView(file.url || file.SRC)"
          />
          <div class="xa-upload-btn_delete" @click.stop.prevent="onDelete">
            <el-button type="danger" icon="el-icon-delete" circle></el-button>
          </div>
          <div v-if="!file.STATUS" class="xa-upload__progress">
            <el-progress
              :percentage="file.PROGRESS"
              status="warning"
            ></el-progress>
          </div>
        </div>
      </template>
      <div
        v-show="!disable"
        style="order:10000"
        class="xa-upload__item xa-upload__item--add"
        :style="imgSize"
        @click="onAdd"
      ></div>
    </div>
    <template v-if="inputCreate">
      <input style="display: none" type="file" ref="fileinput" />
    </template>
  </div>
</template>
<script>
import { uploadFile } from '@/controllers/upload.js'
import request from '@/apis/index'
export default {
  name: 'uploadCom',
  data() {
    return {
      uploadList: [],
      inputCreate: true // 为了解决上传相同的图片不触发onchange事件
    }
  },
  computed: {
    disable() {
      return this.uploadList.length >= 1
    },
    topTip() {
      const tip = '请点击 + 号选择照片'
      const tip1 = '已达到上传张数限制，可删除已选择的照片'
      return this.disable ? tip1 : tip
    },
    imgSize() {
      if (this.size && this.size instanceof Array) {
        return {
          width: this.size[0] + 'px',
          height: this.size[1] + 'px'
        }
      }
      return {}
    }
  },
  props: {
    actionUrl: {
      type: String
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    accept: {
      type: String,
      default: 'image/jpg,image/jpeg,image/png,image/gif'
    },
    resourceUrl: {
      type: String,
      default: '/resource/home/get_resource_urls'
    },
    thumbSize: {
      type: Object,
      default() {
        return {
          width: 1024,
          height: 1024
        }
      }
    },
    size: Array
  },
  methods: {
    onDelete() {
      this.uploadList.pop()
      this.updateVariables(this.uploadList)
    },
    preView(url) {
      let preViewImgList = this.uploadList.map(item => item.url || item.SRC)
      this.$preViewImg({ isShow: true, imgSrc: url, imgList: preViewImgList })
    },
    extractFileInfo({ name, size, type }) {
      return {
        name,
        size,
        type,
        loaded: 0,
        STATUS: false,
        PROGRESS: 0,
        URID: -1,
        URL: ''
      }
    },
    /**
     * 获取可以上传的文件数
     * 受限于设置的max参数，以及已经上传数
     */
    getCanUploadCount(files) {
      let canUploadCount = files.length
      canUploadCount = Math.min(1 - this.uploadList.length, canUploadCount)
      return canUploadCount
    },
    handleUploadFile(files) {
      const canUploadCount = this.getCanUploadCount(files)
      for (let i = 0, len = canUploadCount; i < len; i++) {
        let file = files[i]
        let curImg = {
          SRC: '',
          ...this.extractFileInfo(file)
        }
        this.uploadFile(file, curImg)
          .then(() => this.updateVariables(this.uploadList))
          .catch(error => {
            this.$message.error(error.message)
          })
        this.uploadList.push(curImg)
        this.displayImgFile(file, curImg)
      }
    },
    displayImgFile(file, curImg) {
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = e => {
        curImg.SRC = e.target.result
      }
    },
    /**
     * 提交图片，只提交uid就行了
     */
    updateVariables(list) {
      let uids = list.map(img => img.UID)
      this.$emit('input', uids)
    },
    uploadFile(file, fileInfo = {}) {
      return uploadFile(this.actionUrl, {
        files: [file],
        onprogress: function(e) {
          fileInfo.PROGRESS = Math.floor((100 * e.loaded) / e.total)
        }
      })
        .then(data => {
          fileInfo.STATUS = true
          fileInfo.UID = data[0]
          return fileInfo.UID
        })
        .catch(error => {
          this.$message.error(error.message)
        })
    },
    /**
     * 创建一个文件上传的input
     */
    onAdd() {
      const input = this.$refs.fileinput
      input.accept = this.accept
      let onchangeFn = () => {
        if (input.files && input.files.length) {
          this.handleUploadFile(input.files)
        }
      }
      input.onchange = onchangeFn
      setTimeout(() => {
        input.click()
      }, 0)
    },
    /**
     * 图片是七牛上的key
     */
    async initDataFormQiniu(values, thumbSize = this.thumbSize) {
      const data = await request({
        url: this.resourceUrl,
        data: {
          res_uids: JSON.stringify(values),
          ...thumbSize
        },
        method: 'post'
      })
      data.forEach(img => {
        img.STATUS = true
        img.UID = img.uid
      })
      this.uploadList = data
    },
    async initDataFormOrigin(values) {
      values.forEach(item => {
        this.uploadList.push({
          STATUS: true,
          UID: item,
          thumb_url: item
        })
      })
    },
    async initFn() {
      this.uploadList = []
      if (this.value && this.value.length > 0) {
        //  两个情况 1是http的图片地址 2是七牛的key
        if (this.value.some(i => i.indexOf('http') !== 0)) {
          this.initDataFormQiniu(this.value)
        } else {
          this.initDataFormOrigin(this.value)
        }
      }
    }
  },
  created() {
    this.initFn()
  }
}
</script>
<style scoped lang="scss">
.xa-upload__wrap {
  width: min-content;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    border: 1px solid #20a0ff;
  }
}
.xa-upload__item {
  position: relative;
  width: 84px;
  height: 110px;
  overflow: hidden;
}

.xa-upload__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.xa-upload__item--add {
  &::before {
    content: ' ';
    width: 2px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
  &::after {
    content: ' ';
    position: absolute;
    width: 40px;
    height: 2px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
  &:hover {
    &::after {
      background: #20a0ff;
    }

    &::before {
      background: #20a0ff;
    }
  }
}

.xa-upload-btn_delete {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  cursor: pointer;
}

.xa-upload__item:hover .xa-upload-btn_delete {
  opacity: 1;
}

.xa-upload__progress {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
}
</style>
