<template>
  <div class="xa-cell xa-upload__wrap" v-loading="isLoading">
    <template v-for="file in uploadList">
      <div :key="file.thumb_url" :style="imgSize" class="xa-upload__item">
        <img
          class="xa-upload-img"
          :src="file.thumb_url || file.SRC"
          @click.self="preView(file.url || file.SRC)"
        />
      </div>
    </template>
    <div
      v-if="uploadList.length === 0"
      class="xa-txt-placeholder xa-upload__no-data"
    >
      没有图片
    </div>
  </div>
</template>
<script>
import request from '@/apis/index'
export default {
  name: 'uploadCom',
  components: {},
  data() {
    return {
      isLoading: false,
      uploadList: []
    }
  },
  computed: {
    imgSize() {
      if (this.size && this.size instanceof Array) {
        return {
          width: this.size[0] + 'px',
          height: this.size[1] + 'px'
        }
      }
      return {}
    }
  },
  watch: {
    value() {
      this.isLoading = true
      this.initFn()
    }
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    resourceUrl: {
      type: String,
      default: '/resource/home/get_resource_urls'
    },
    thumbSize: {
      // 限制图片大小
      type: Object,
      default() {
        return {
          width: 750,
          height: 750
        }
      }
    },
    size: Array
  },
  methods: {
    preView(url) {
      let preViewImgList = this.uploadList.map(item => item.url || item.SRC)
      this.$preViewImg({ isShow: true, imgSrc: url, imgList: preViewImgList })
    },
    /**
     * 图片是七牛上的key
     */
    async initDataFormQiniu(values, thumbSize = this.thumbSize) {
      const data = await request({
        url: this.resourceUrl,
        data: {
          res_uids: JSON.stringify(values),
          ...thumbSize
        },
        method: 'post'
      })
      data.forEach(img => {
        img.INDEX = this.INDEX++
        img.STATUS = true
        img.UID = img.uid
      })
      this.uploadList = data
    },
    async initDataFormOrigin(values) {
      this.uploadList = []
      values.forEach(item => {
        this.uploadList.push({
          INDEX: this.INDEX++,
          STATUS: true,
          UID: item,
          thumb_url: item
        })
      })
    },
    async initFn() {
      if (this.value && this.value.length > 0) {
        //  两个情况 1是http的图片地址 2是七牛的key
        if (this.value.some(i => i.indexOf('http') !== 0)) {
          await this.initDataFormQiniu(this.value)
        } else {
          this.initDataFormOrigin(this.value)
        }
      } else {
        this.uploadList = []
      }
      this.isLoading = false
    }
  },
  created() {
    this.initFn()
  }
}
</script>
<style scoped lang="scss">
.xa-upload__wrap {
  box-sizing: border-box;
  padding: 4px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  flex-wrap: wrap;
}

.xa-upload__item {
  position: relative;
  box-sizing: border-box;
  width: 84px;
  margin: 4px;
  height: 110px;
  border: 1px solid #d9d9d9;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.xa-upload__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xa-upload-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.xa-upload__no-data {
  flex: 1;
  line-height: 80px;
  text-align: center;
}
</style>
