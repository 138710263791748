<template>
  <div
    class="xa-upload"
    :class="{ 'xa-upload--disable': disable, 'xa-upload--dragOver': dragOver }">
    <div class="xa-upload__head xa-cell xa-txt-secondary">
      <span class="xa-flex" v-html="topTip"></span>
      <div
        v-show="uploadList.length >= 2"
        @click="toolBtnClickFn({ action: 'sort' })"
        class="xa-pointer"
      >
        <i class="iconfont icon-iconzhenghe32"></i>排序
      </div>
      <span class="xa-mgl-16" :class="{ 'xa-txt-red': disable }" v-if="max > 1">{{ totalCount }}/{{ max }}</span>
    </div>
    <template v-if="sortViewDisplay">
      <vuedraggable v-model="sortImg" class="xa-upload__wrap flash">
        <transition-group name="list-complete">
          <div v-for="img in sortImg" :key="img.INDEX" class="xa-upload__item">
            <img class="xa-upload-img" :src="img.thumb_url || img.SRC" />
          </div>
        </transition-group>
      </vuedraggable>
    </template>
    <div
      v-show="!sortViewDisplay"
      class="xa-cell xa-upload__wrap"
      :class="'upload__count-' + this.max"
      ref="dropArea"
    >
      <template v-for="file in uploadList">
        <div
          :key="file.thumb_url"
          :style="imgSize"
          class="xa-upload__item"
          v-loading="!file.STATUS"
        >
          <img
            class="xa-upload-img"
            :src="file.thumb_url || file.SRC"
            @click.self="preView(file.url || file.SRC)"
          />
          <div
            class="xa-upload-btn_delete"
            @click.stop.prevent="newDelete(file)"
          >
            删除
          </div>
          <div
            v-if="!file.STATUS"
            class="xa-upload-tip_progress"
            :style="{ width: file.PROGRESS + '%' }"
          ></div>
        </div>
      </template>
      <div
        v-show="!disable"
        style="order:10000"
        class="xa-upload__item xa-upload__item--add"
        :style="imgSize"
        @click="onAddNewUpload"
      ></div>
    </div>
    <template v-if="inputCreate">
      <input style="display: none" type="file" ref="fileinput" />
    </template>
    <div class="xa-mgt-16">
      <div class="xa-cell" v-show="toolsDisplay">
        <div class="xa-flex"></div>
        <el-button
          size="mini"
          @click="toolBtnClickFn({ action: 'confirm' })"
          type="primary"
          round
          >{{ $t('Confirm') }}</el-button
        >
        <el-button
          size="mini"
          @click="toolBtnClickFn({ action: 'cancel' })"
          round
          >{{ $t('Cancel') }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile } from '@/controllers/upload.js'
import request from '@/apis/index'
import vuedraggable from 'vuedraggable'
export default {
  name: 'uploadCom',
  components: {
    vuedraggable
  },
  data() {
    return {
      INDEX: 0,
      uploaded_file: [], // 组件初始化前 就已经上传的照片 {thumb_url,uid,uri,url,"INDEX"}
      dragOver: false,
      toolsDisplay: false,
      sizeStatus: {
        status: 0
      },
      uploadList: [],
      sortImg: [],
      sortViewDisplay: false,
      orderList: [], // 照片顺序
      inputCreate: true // 为了解决上传相同的图片不触发onchange事件
    }
  },
  computed: {
    disable() {
      if (this.max > 0) {
        return this.uploadList.length >= this.max
      }
      return false
    },
    topTip() {
      const tip = '拖拽照片到下面区域或者点击 + 号选择照片'
      const tip1 = '已达到上传张数限制，可删除已选择的照片'
      const tip2 = '请拖拽下面区域内的图片排序'
      if (this.toolsDisplay) return tip2
      if (this.disable) return tip1
      if (!this.disable) return tip
      return tip2
    },
    totalCount() {
      return this.uploadList.length
    },
    imgSize() {
      if (this.size && this.size instanceof Array) {
        return {
          width: this.size[0] + 'px',
          height: this.size[1] + 'px'
        }
      }
      return {}
    }
  },
  props: {
    actionUrl: {
      type: String
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    max: {
      type: [String, Number],
      default: 0
    },
    accept: {
      type: String,
      default: 'image/jpg,image/jpeg,image/png,image/gif'
    },
    resourceUrl: {
      type: String,
      default: '/resource/home/get_resource_urls'
    },
    thumbSize: {
      type: Object,
      default() {
        return {
          width: 750,
          height: 750
        }
      }
    },
    size: Array
  },
  methods: {
    newDelete(file) {
      let index = this.uploadList.indexOf(file)
      this.uploadList.splice(index, 1)
      this.updateVariables(this.uploadList)
    },
    onAddNewUpload() {
      this.createInput()
    },
    toolBtnClickFn(btn) {
      if (btn.action === 'sort') {
        if (this.toolsDisplay === false) {
          this.sortViewDisplay = this.toolsDisplay = true
          this.sortImg = this.uploadList
        } else {
          this.sortViewDisplay = this.toolsDisplay = false
        }
      } else if (btn.action === 'confirm') {
        this.uploadList = []
        this.$nextTick(() => {
          this.uploadList = this.sortImg
          this.sortImg = []
          this.updateVariables(this.uploadList)
        })
        this.sortViewDisplay = false
        this.toolsDisplay = false
      } else if (btn.action === 'cancel') {
        this.sortImg = []
        this.sortViewDisplay = false
        this.toolsDisplay = false
      }
    },
    preView(url) {
      let preViewImgList = this.uploadList.map(item => item.url || item.SRC)
      this.$preViewImg({ isShow: true, imgSrc: url, imgList: preViewImgList })
    },
    extractFileInfo({ name, lastModified, size, type }) {
      return {
        name,
        lastModified,
        size,
        type,
        loaded: 0,
        STATUS: false,
        PROGRESS: 0,
        URID: -1,
        URL: ''
      }
    },
    /**
     * 获取可以上传的文件数
     * 受限于设置的max参数，以及已经上传数
     */
    getCanUploadCount(files) {
      let canUploadCount = files.length
      if (this.max > 0) {
        canUploadCount = Math.min(
          this.max - this.uploadList.length,
          canUploadCount
        )
      }
      return canUploadCount
    },
    newHandlerFile(files) {
      const canUploadCount = this.getCanUploadCount(files)
      for (let i = 0, len = canUploadCount; i < len; i++) {
        let file = files[i]
        let curImg = {
          INDEX: this.INDEX++,
          SRC: '',
          ...this.extractFileInfo(file)
        }
        this.uploadFile(file, curImg)
          .then(() => this.updateVariables(this.uploadList))
          .catch(error => {
            this.$message.error(error.message)
          })
        this.uploadList.push(curImg)
        this.displayImgFile(file, curImg)
      }
    },
    displayImgFile(file, curImg) {
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = e => {
        curImg.SRC = e.target.result
      }
    },
    /**
     * 提交图片，只提交uid就行了
     */
    updateVariables(list) {
      let uids = list.map(img => img.UID)
      this.$emit('input', uids)
    },
    uploadFile(file, fileInfo = {}) {
      return uploadFile(this.actionUrl, {
        files: [file],
        onprogress: function(e) {
          fileInfo.PROGRESS = Math.floor((100 * e.loaded) / e.total)
        }
      })
        .then(data => {
          fileInfo.STATUS = true
          fileInfo.UID = data[0]
          return fileInfo.UID
        })
        .catch(error => {
          this.$message.error(error.message)
        })
    },
    fileDrop(e) {
      this.dragOver = false
      if (!this.disable) return
      let files = e.dataTransfer.files // FileList
      this.newHandlerFile(files)
    },
    /**
     * 创建一个文件上传的input
     */
    createInput() {
      let input = this.$refs.fileinput
      if (!input.onchange) {
        input.accept = this.accept
        input.multiple = true
        let onchangeFn = () => {
          if (input.files && input.files.length) {
            this.newHandlerFile(input.files)
          }
        }
        input.onchange = onchangeFn
        setTimeout(() => {
          input.click()
        }, 0)
      } else {
        input.click()
      }
    },
    /**
     * 图片是七牛上的key
     */
    async initDataFormQiniu(values, thumbSize = this.thumbSize) {
      const data = await request({
        url: this.resourceUrl,
        data: {
          res_uids: JSON.stringify(values),
          ...thumbSize
        },
        method: 'post'
      })
      data.forEach(img => {
        img.INDEX = this.INDEX++
        img.STATUS = true
        img.UID = img.uid
      })
      this.uploadList = data
    },
    async initDataFormOrigin(values) {
      values.forEach(item => {
        this.uploadList.push({
          INDEX: this.INDEX++,
          STATUS: true,
          UID: item,
          thumb_url: item
        })
      })
    },
    async initFn() {
      this.uploadList = []
      if (this.value && this.value.length > 0) {
        //  两个情况 1是http的图片地址 2是七牛的key
        if (this.value.some(i => i.indexOf('http') !== 0)) {
          this.initDataFormQiniu(this.value)
        } else {
          this.initDataFormOrigin(this.value)
        }
      }
    }
  },
  created() {
    this.initFn()
  },
  mounted() {
    let vm = this
    this.$refs.dropArea.addEventListener(
      'dragover',
      function(e) {
        e = e || window.event
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy' // 指定拖放视觉效果
        vm.dragOver = true
      },
      false
    )
    this.$refs.dropArea.addEventListener(
      'dragleave',
      function() {
        vm.dragOver = false
      },
      false
    )
    this.$refs.dropArea.addEventListener(
      'drop',
      function(e) {
        e = e || window.event
        e.stopPropagation()
        e.preventDefault()
        vm.fileDrop(e)
      },
      false
    )
    this.$refs.dropArea.addEventListener(
      'paste',
      e => {
        if (e.clipboardData.items.length) {
          let files = []
          for (var i = 0; i < e.clipboardData.items.length; i++) {
            var item = e.clipboardData.items[i]
            files.push(item.getAsFile())
          }
          this.newHandlerFile(files)
        }
      },
      false
    )
  }
}
</script>
<style scoped lang="scss">
.xa-upload {
  &__head {
    margin-bottom: 4px;
  }
}
.xa-upload__wrap {
  box-sizing: border-box;
  padding: 4px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  flex-wrap: wrap;
}
.xa-upload--dragOver .xa-upload__wrap,
.xa-upload__wrap:hover {
  border: 1px solid;
  border-color: $color-primary;
}
.xa-upload--disable.xa-upload--dragOver .xa-upload__wrap {
  border-style: dashed;
  border-color: $color-red;
}
.xa-upload__item {
  position: relative;
  box-sizing: border-box;
  width: 84px;
  height: 110px;
  border: 1px solid #d9d9d9;
  margin: 4px 4px 4px 0;
  overflow: hidden;
  transition: all 0.3s ease;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.xa-upload__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xa-upload-img {
  max-width: 100%;
  max-height: 100%;
}

.xa-upload__item:first-child {
  margin-left: 0;
}

.xa-upload__item--add {
  &::before {
    content: ' ';
    width: 2px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
  &::after {
    content: ' ';
    position: absolute;
    width: 40px;
    height: 2px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #d9d9d9;
  }
}

.xa-upload__item--add:hover::after {
  background: $color-primary;
}

.xa-upload__item--add:hover::before {
  background: $color-primary;
}

.xa-upload-btn_delete {
  width: 100%;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  position: absolute;
  bottom: -25px;
  left: 0;
  /*border-top: 1px solid #fff;*/
  text-align: center;
  color: #fff;
  background: $color-red;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}

.xa-upload__item:hover .xa-upload-btn_delete {
  bottom: 0;
}

.xa-upload-tip_progress {
  position: absolute;
  left: 0;
  bottom: 0;
  background: $color-primary;
  z-index: 100000;
  height: 4px;
  transition: all 0.5s linear;
}

.flash {
  animation: moves 2s linear 0.1s 2 alternate;
  -webkit-animation: moves 2s linear 0.1s 2 alternate;
}
.upload__count-1 {
  width: min-content;
  padding-right: 0;
  .xa-upload__item {
    width: 125px;
    height: 175px;
  }
}

@keyframes moves {
  100% {
    background-color: rgba(118, 184, 234, 0.48);
  }
  75% {
    background-color: #fff;
  }
  50% {
    background-color: rgba(118, 184, 234, 0.48);
  }
  25% {
    background-color: #fff;
  }
  0% {
    background-color: rgba(118, 184, 234, 0.48);
  }
}
</style>
