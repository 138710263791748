<script>
import Edit from '../Upload.vue'
import EditSingle from '../UploadSingle.vue'
import View from '../upload/view.vue'
export default {
  functional: true,
  render(h, context) {
    const { mode, max } = context.props
    let editComs = Edit
    if (max === 1 || max === '1') {
      editComs = EditSingle
    }
    return h(mode === 'view' ? View : editComs, context.data, context.children)
  }
}
</script>
